import React, {Component} from 'react'
import SideMenu from '../admin/SideMenu';
import {Modal} from 'reactstrap';
import TopMenu from '../admin/TopBar';
import {appDatabasePrimaryFunctions, base} from '../../base';
import '../../styles/css/AdminMain.css';
import swal from 'sweetalert2';
import DatePicker from '../utils/DatePicker';
import {
  cleanCSVText,
  convertTimeStampToHumanReadable, detectHowManyCodesEntered,
  findFirstAndLastName,
  getSnapshotFromEndpoint,
  isObjectEmpty,
  validUrl
} from '../utils/HelpfulFunction';

class SetUpGame extends Component {
    constructor(props) {
        super(props);
        this.state = {
          select: {},
          ticket_select: {},
          ticketList: [],
          users: [],
          schedule_game_time: new Date(),
          schedule_game: false,
          rewardsAdded: [],
          rewardToAdd: '',
          rewardAmount: 0,
          codes: false,
          links: false,
          qrCodes: false,
          tenantRules: {},
          activateGame: {},
          currentGameRewards: [],
          currentGame: null,
          modal: false,
          gameName: null,
          userGameHistory: [],
          loading: true,
          wizardLocation: 'first',
          showStats: true,
          advanceSettings: false,
          gamesList: [],
          emailsToSend: {},
          randomOrder: false,
          participants: "game"
        };
        this.handleChange = this.handleChange.bind(this);
        this.toggle = this.toggle.bind(this);
        this.saveReward = this.saveReward.bind(this);
    }

    componentDidMount() {
      const vm = this;
      this.tenantVariablesRef = base.bindToState(`tenantVariables`, {
        context: this,
        state: 'tenantVariables',
      });
      this.tenantRulesRef = base.bindToState(`tenantRules`, {
        context: this,
        state: 'tenantRules',
      });
      this.currentGameRef = base.syncState(`currentGame`, {
        context: this,
        state: 'currentGame',
        then(err){
          this.setState({
            loading: false,
          })
          if(!err && this.state.currentGame.id){
            this.setGameToWatch(this.state.currentGame.id)
            this.getRedemptions(this.state.currentGame.id);
          }
        },
        onFailure(err){
          // console.log(err)
          this.setState({
            loading: false,
          })
        }
      })
      this.ticketListRef = base.bindToState(`rewardsList`, {
        context: this,
        state: 'ticketList',
        asArray: true
      });
      this.formQuestionsRef = base.bindToState('formQuestions', {
        context: this,
        asArray: true,
        state: 'formQuestions'
      })
      this.campaignRegistrantsRef = appDatabasePrimaryFunctions.ref('campaignRegistrants').on('value', function(snapshot){
        vm.setState({
          campaignRegistrantsCount: snapshot.numChildren()
        })
      });
    }

    async getRedemptions(gameId) {
      if (!gameId) return;
      this.redeemedPrizeRef = base.listenTo(`redeemedPrize`, {
        context: this,
        queries: {
          orderByChild: 'gameId',
          equalTo: gameId
        },
        then(data){
          let countOfWinners = 0;
          for(const i in data){
            if(data[i].redeemed){
              countOfWinners++;
            }
          }
          this.setState({
            redemptions: countOfWinners
          })
        },
        onFailure(err){
          console.log(err)
        }
      })
    }

    downloadUsers(){
      this.setState({loading: true});
      const vm = this;
      let csv = 'Email,First Name,Last Name,Other First Name,Other Last Name,Zip Code,Street,City,State,Address,Birthday,Phone Number,Social Handle,Fan Response Text,Short Text Response 1,Short Text Response 2,Custom Dropdown,Opt-In,Opt-In 2,Opt-In 3,Opt-In 4,YesNoQuestions,YesExplanation,How They Heard,User Image,UTM Campaign,UTM Medium,UTM Source,SRC Code,Reward,Code,Redeemed';
      const newColumnsArray = [];
      if(this.state.formQuestions){
        for(const i in this.state.formQuestions){
          const formQuestion = this.state.formQuestions[i];
          newColumnsArray.push(formQuestion.id)
          csv+=(","+cleanCSVText(formQuestion.text||""))
        }
        if(!this.props.isMacuTeam && this.state.tenantVariables.otherResponse){
          csv+=(",Other")
        }
      }
      csv+="\n";
      Promise.all([getSnapshotFromEndpoint('users', appDatabasePrimaryFunctions)]).then(async function(snapshots){
        let users = {};
        if(snapshots[0].exists() && snapshots[0].val()){
          users = snapshots[0].val();
        }
        const currentUsersState = await vm.sortPlayersForAdmin();
        currentUsersState[0].forEach(function(row) {
          let nameResponse = findFirstAndLastName(users[row.uid].name);
          let otherNameResponse = findFirstAndLastName(users[row.uid].otherName);
          csv += (row.email || row.uid || "");
          csv+= ",";
          csv+= (cleanCSVText(nameResponse[0]) || "");
          csv+= ",";
          csv+= (cleanCSVText(nameResponse[1]) || "");
          csv+= ",";
          csv+= (cleanCSVText(otherNameResponse[0]) || "");
          csv+= ",";
          csv+= (cleanCSVText(otherNameResponse[1]) || "");
          csv+= ",";
          csv+= (cleanCSVText(users[row.uid].zipCode) || "");
          csv+= ",";
          csv+= (cleanCSVText(users[row.uid].street) || "");
          csv+= ",";
          csv+= (cleanCSVText(users[row.uid].city) || "");
          csv+= ",";
          csv+= (cleanCSVText(users[row.uid].state) || "");
          csv+= ",";
          csv+= (cleanCSVText(users[row.uid].address) || "");
          csv+= ",";
          csv+= (users[row.uid].birthday || "");
          csv+= ",";
          csv+= (users[row.uid].phoneNumber || "");
          csv+= ",";
          csv+= (users[row.uid].socialHandle || "");
          csv+= ",";
          csv+= (cleanCSVText(users[row.uid].fanResponseToCollectTextInput) || "");
          csv+= ",";
          csv+= (cleanCSVText(users[row.uid].textInputOne) || "");
          csv+= ",";
          csv+= (cleanCSVText(users[row.uid].textInputTwo) || "");
          csv+= ",";
          csv+= (cleanCSVText(users[row.uid].customDropdownInput) || "");
          csv+= ",";
          csv+= (users[row.uid].optIn || "");
          csv+= ",";
          csv+= (users[row.uid].optInTwo || "");
          csv+= ",";
          csv+= (users[row.uid].optInThree || "");
          csv+= ",";
          csv+= (users[row.uid].optInFour || "");
          csv+= ",";
          csv+= (users[row.uid].yesNoQuestions || "");
          csv+= ",";
          csv+= (cleanCSVText(users[row.uid].yesExplanation) || "");
          csv+= ",";
          csv+= (users[row.uid].howYouHeard || "");
          csv+= ",";
          csv+= (users[row.uid].userImage || "");
          csv+= ",";
          csv+= (cleanCSVText(users[row.uid].utmCampaign) || "");
          csv+= ",";
          csv+= (cleanCSVText(users[row.uid].utmMedium) || "");
          csv+= ",";
          csv+= (cleanCSVText(users[row.uid].utmSource) || "");
          csv+= ",";
          csv+= (cleanCSVText(users[row.uid].srcCode) || "");
          csv+= ",";
          csv+= (cleanCSVText(row.rewardSent) || "NONE");
          csv+= ",";
          csv+= (cleanCSVText(row.code) || "-")
          csv+= ",";
          csv+= (row.isRedeemed ? "true":"false");
          for(const indexOfColumnArray in newColumnsArray){
            const columnHeaderName = newColumnsArray[indexOfColumnArray];
            csv+= ",";
            if(users[row.uid] && users[row.uid].form_responses && (users[row.uid].form_responses[columnHeaderName] === false || users[row.uid].form_responses[columnHeaderName] === true)){
              csv += (users[row.uid].form_responses[columnHeaderName] || "");
            } else {
              csv += (row[columnHeaderName] || "");
            }
          }
          if(vm.state.tenantVariables.otherResponse){
            csv+= ",";
            csv+= (cleanCSVText(users[row.uid].otherInput) || "");
          }
          csv += "\n";
        }, this);
        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        hiddenElement.target = '_blank';
        const date_got = new Date((new Date().getTime() - new Date().getTimezoneOffset()*60*1000)).toISOString().split("T")[0] + ".csv";
        hiddenElement.download = "current_game_users_" + date_got;
        hiddenElement.click();
        vm.setState({loading: false});
      })
    }

    setGameToWatch(id){
      if(this.userGameHistoryRef){
        appDatabasePrimaryFunctions.ref('userGameHistory').off('value', this.userGameHistoryRef);
      }
      if(id){
        const vm = this;
        this.userGameHistoryRef = appDatabasePrimaryFunctions.ref("userGameHistory").orderByChild(id).equalTo(id).on('value', function(snapshot){
          vm.setState({
            userGameHistory: snapshot.numChildren()
          })
        })
      }
    }

    componentWillUnmount() {
      base.removeBinding(this.currentGameRef);
      base.removeBinding(this.ticketListRef);
      base.removeBinding(this.tenantVariablesRef);
      base.removeBinding(this.campaignRegistrantsRef);
      base.removeBinding(this.tenantRulesRef);
      base.removeBinding(this.formQuestionsRef);
      if(this.redeemedPrizeRef){
        base.removeBinding(this.redeemedPrizeRef);
      }
      if(this.userGameHistoryRef){
        appDatabasePrimaryFunctions.ref('userGameHistory').off('value', this.userGameHistoryRef);
      }
    }

    createGame(){
      const createGameObject = {};
      const gameId = appDatabasePrimaryFunctions.ref().push().key;
      const futureGames = this.state.futureGamesList || [];
      const gameName = this.state.gameName;
      const participants = this.state.participants;
      const gameStartTime = this.state.schedule_game_time;
      const scheduledGame = this.state.schedule_game;
      let chanceToWin = this.state.chanceToWin || null;
      const currentTimeSeconds = Math.floor(Date.now());
      const inputtedDateSeconds = gameStartTime.getTime();
      if(participants === "immediate" && (!chanceToWin || chanceToWin < 1)){
        swal({
          title: "Hold On!",
          text: "Immediate games needs a chance to win",
          type: 'warning',
          confirmButtonText: 'Ok'
        })
        return;
      } else if(participants === "immediate"){
        chanceToWin = parseInt(chanceToWin);
      }
      if(!gameName){
        swal({
            title: "Hold On!",
            text: "You must give the game a name!",
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        return;
      }
      if(scheduledGame){
        if(inputtedDateSeconds <= currentTimeSeconds){
          swal({
              title: "Hold On!",
              text: "You have marked that you are scheduling a game but it is before now!",
              type: 'warning',
              confirmButtonText: 'Ok'
            })
          return;
        } else if(!inputtedDateSeconds) {
          swal({
            title: "Hold On!",
            text: "You have marked that you are scheduling but no game time entered!",
            type: 'warning',
            confirmButtonText: 'Ok'
          })
          return;
        }
        for(const fGameIndex in futureGames){
          const futureGame = futureGames[fGameIndex];
          if(inputtedDateSeconds === futureGame.activateGame.performAt){
            swal({
              title: "Hold On!",
              text: "You already have a scheduled game at that time!",
              type: 'warning',
              confirmButtonText: 'Ok'
            })
            return;
          }
        }
      }
      const rewardsAdded = this.state.rewardsAdded;
      const readyToUploadPrizes = {};
      let hasRewardWithAutoRedemptions = false;
      if(rewardsAdded.length !== 0 && !this.state.noPrizes){
        let totalAmount = 0;
        for(const rewardIndex in rewardsAdded){
          readyToUploadPrizes[rewardsAdded[rewardIndex].id] = Object.assign({}, rewardsAdded[rewardIndex].reward);
          readyToUploadPrizes[rewardsAdded[rewardIndex].id]['amount'] = rewardsAdded[rewardIndex].amount? parseInt(rewardsAdded[rewardIndex].amount) : 0;
          totalAmount+=(parseInt(rewardsAdded[rewardIndex].amount) || 0);
          readyToUploadPrizes[rewardsAdded[rewardIndex].id]['codes'] = rewardsAdded[rewardIndex].codes || false;
          readyToUploadPrizes[rewardsAdded[rewardIndex].id]['links'] = rewardsAdded[rewardIndex].links || false;
          readyToUploadPrizes[rewardsAdded[rewardIndex].id]['qrCodes'] = rewardsAdded[rewardIndex].qrCodes || false;
          readyToUploadPrizes[rewardsAdded[rewardIndex].id]['allElsePrize'] = rewardsAdded[rewardIndex].allElsePrize || false;
          readyToUploadPrizes[rewardsAdded[rewardIndex].id]['codesArray'] = rewardsAdded[rewardIndex].codesArray || [];
          readyToUploadPrizes[rewardsAdded[rewardIndex].id]['linksArray'] = rewardsAdded[rewardIndex].linksArray || [];
          readyToUploadPrizes[rewardsAdded[rewardIndex].id]['pinsArray'] = rewardsAdded[rewardIndex].pinsArray || [];
          readyToUploadPrizes[rewardsAdded[rewardIndex].id]['used'] = 0;
          readyToUploadPrizes[rewardsAdded[rewardIndex].id]['isRedeemable'] = rewardsAdded[rewardIndex].reward.isRedeemable || false;
          if(readyToUploadPrizes[rewardsAdded[rewardIndex].id]['isRedeemable']){
            hasRewardWithAutoRedemptions = true;
          }
        }
        readyToUploadPrizes["totalRewards"] = totalAmount;
        readyToUploadPrizes["totalRewardsUsed"] = 0;
        readyToUploadPrizes["randomPrizeOrder"] = this.state.randomOrder || false;
      }
      if(rewardsAdded.length === 0 && !this.state.noPrizes){
        swal({
          title: "Hold On!",
          text: 'You have no prizes added to the game! You need at least 1',
          type: 'warning',
          confirmButtonText: 'Ok'
        })
        return;
      }
      createGameObject.rewards = readyToUploadPrizes;
      createGameObject.status = 0;
      createGameObject.gameName = gameName;
      createGameObject.active = false;
      createGameObject.participants = participants;
      createGameObject.gameType = "sendprizes";
      createGameObject.chanceToWin = chanceToWin;
      createGameObject.hasRewardWithAutoRedemptions = hasRewardWithAutoRedemptions;
      let activateGame = {};
      let shouldReplaceCurrentGame  = true
      const currentGame = this.state.currentGame || {};
      if(scheduledGame){
        activateGame['performAt'] = inputtedDateSeconds;
        activateGame['status'] = "scheduled";
        if(currentGame.gameName){
          shouldReplaceCurrentGame = false;
          createGameObject['activateGame'] = activateGame
        }
      } else {
        activateGame = null
        createGameObject['activateGame'] = false
      }

      createGameObject.timeStamp = Date.now()
      createGameObject.id = gameId;
      const vm = this;
      if(shouldReplaceCurrentGame){
        this.saveDeleteCurrentGameDataReplace(currentGame, createGameObject);
      } else if(createGameObject.id) {
        this.setState({
          loading: true
        })
        base.post('futureGamesList/'+ createGameObject.id, {
          data: createGameObject,
          then(err){
            if(!err){
              vm.setState({
                modal: false
              })
            }
            vm.setState({
              loading: false
            })
          }
        })
      }
    }

    async saveDeleteCurrentGameDataReplace(currentGame, createGameObject, fromMakeFutureGameCurrent=false){
      this.setState({
        loading: true
      })
      if(currentGame.gameName){
        if(currentGame.participants === "allTime"){
          currentGame['users'] = this.state.campaignRegistrantsCount || 0;
        } else {
          currentGame['users'] = this.state.userGameHistory || 0;
        }
        currentGame['prizes'] = await appDatabasePrimaryFunctions.ref('emailsSent').once('value').then(function (snapshot) {
          return snapshot.numChildren();
        });
        const organizeUserAnswers = await this.sortPlayersForAdmin();
        base.post(`userAnswersHistory/${currentGame.id}`, {
          data: organizeUserAnswers[0],
          then(data){
            console.log("User Answers Saved!")
          }
        })
      }

      await base.remove("prizeWon");
      await base.remove("currentGame/rewards");
      await base.remove("currentGame/activateGame");

      const vm = this;
      if(currentGame){
        base.push('gamesList', {
          data: currentGame,
          then(err){
            if(!err){
              vm.setState({
                currentGame: createGameObject,
                currentUserAnswersState: [],
                modal: false
              }, ()=>{
                base.post(`emailsToSend`, {
                  data: null,
                  then(err){
                    if(!err){
                      console.log("success")
                    }
                  }
                })
                base.post(`emailsSent`, {
                  data: null,
                  then(err){
                    if(!err){
                      console.log("success")
                    }
                  }
                })
                base.remove("futureEmailsToSend");
                if(fromMakeFutureGameCurrent){
                  vm.removeGameFromFutureList(createGameObject.id)
                }
              })
            }
            vm.setState({
              loading: false
            })
          }
        })
      } else {
        this.setState({
          loading: false
        })
      }
      this.setGameToWatch(createGameObject.id)
      this.getRedemptions(createGameObject.id);
    }

    async removeGameFromFutureList(gameToRemoveId){
      var futureGameList = this.state.futureGamesList;
      for(var gameIndex in futureGameList){
        var futureGame = futureGameList[gameIndex];
        if(futureGame.id === gameToRemoveId){
          futureGameList.splice(gameIndex, 1)
          if(gameToRemoveId){
            base.remove('futureGamesList/'+gameToRemoveId)
          }
        }
      }
      this.setState({
        futureGamesList: futureGameList
      })
    }

    detectHowManyCodesEntered(codesArray, areLinks){
      return detectHowManyCodesEntered(codesArray, areLinks);
    }

    startGame(){
      const currentGame = this.state.currentGame;
      if(currentGame && currentGame.timeStamp){
        currentGame.active = true
        currentGame.timeStamp = Date.now()
        this.setState({
          currentGame: currentGame
        })
      }
    }

    stopGame(){
      const currentGame = this.state.currentGame;
      if(currentGame && currentGame.timeStamp){
        currentGame.active = false
        this.setState({
          currentGame: currentGame
        })
      }
    }

    resetRace(){
      const currentGame = this.state.currentGame;
      swal({
          title: 'STOP!',
          text: 'Are you sure you want to do this?  This will reset peoples picks!',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'YES',
        }).then(async (result)=>{
          if(result.value){
            currentGame.status = 0
            currentGame.sentEmail = false
            currentGame.active = false;
            if(currentGame.rewards){
              currentGame.rewards.totalRewardsUsed = 0;
              for(let rewardObjectParameter in currentGame.rewards){
                const parameter = currentGame.rewards[rewardObjectParameter];
                if(typeof parameter === "object"){
                  parameter.used = 0
                }
              }
            }
            this.setState({
              currentGame: currentGame,
              currentUserAnswersState: []
            })
            await base.remove("prizeWon");
            let history = await appDatabasePrimaryFunctions.ref(`userGameHistory`).orderByChild(currentGame.id).equalTo(currentGame.id).once('value');
            history = history.val();
            for(let historyRef in history){
              let refInstance = history[historyRef];
              if(refInstance && refInstance[currentGame.id]){
                appDatabasePrimaryFunctions.ref(`userGameHistory/${historyRef}/${currentGame.id}`).set(null);
              }
            }
            let redemptions = await appDatabasePrimaryFunctions.ref('redeemedPrize').orderByChild("gameId").equalTo(currentGame.id).once('value');
            redemptions = redemptions.val();
            for(let redemption in redemptions) {
              let refInstance = redemptions[redemption];
              if (refInstance && refInstance.gameId === currentGame.id && currentGame.id) {
                appDatabasePrimaryFunctions.ref(`redeemedPrize/${redemption}`).set(null);
              }
            }
            base.post(`emailsSent`, {
              data: null,
              then(err){
                if(!err){
                  console.log("reset emails sent")
                }
              }
            })
            base.post(`emailsToSend`, {
              data: null,
              then(err){
                if(!err){
                  console.log("reset emails to send")
                }
              }
            })
            swal({
              title: 'Reset Successful',
              type: 'success',
              toast: true,
              position: 'top-end',
              showConfirmButton: true,
              timer: 10000
            })
          }
      })
    }

    handleChange (evt) {
      const target = evt.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      this.setState({ [evt.target.name]: value });
    }

    toggle() {
      this.setState({
        modal: !this.state.modal,
        wizardLocation: "first"
      });
    }

    navButtonClicked(direction){
      const currentLocation = this.state.wizardLocation;
      if(direction === 'prev' && currentLocation === 'second'){
        this.setState({
          wizardLocation: 'first'
        })
        this.toggleWizardLocation('first', 'second', 'third')
      } else if(direction === 'prev' && currentLocation === 'third'){
        this.setState({
          wizardLocation: 'second'
        })
        this.toggleWizardLocation('second', 'first', 'third')
      } else if(direction === 'next' && currentLocation === 'first'){
        this.setState({
          wizardLocation: 'second',
        })
        this.toggleWizardLocation('second', 'first', 'third')
      } else if(direction === 'next' && currentLocation === 'second'){
        this.setState({
          wizardLocation: 'third',
        })
        this.toggleWizardLocation('third', 'first', 'second')
      } else {
        this.setState({
          wizardLocation: 'first'
        })
        this.toggleWizardLocation('first', 'second', 'third')
      }
    }

    async sendRewardsToPlayers(){
      const vm = this;
      this.setState({
        loading: true
      })
      let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api/sendPrizesToParticipants`;
      if(process.env.NODE_ENV === "development"){
        url = `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/api/sendPrizesToParticipants`;
      }
      const bearerToken = await this.props.currentUser.getIdToken();
      const bearerTokenString = "Bearer " + bearerToken;
      const gameId = this.state.currentGame.id;
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'authorization': bearerTokenString
        },
        body: JSON.stringify({language: this.state.language, gameId: gameId})
      }).then(res => {
        if(res){
          Promise.resolve(res.json()).then(async function (value) {
            if(value.result === "finished"){
              const currentUserAnswersState = await vm.sortPlayersForAdmin();
              const currentGame = vm.state.currentGame;
              currentGame['status'] = 2;
              vm.setState({
                currentUserAnswersState: currentUserAnswersState[0],
                showLinkColumn: currentUserAnswersState[1],
                loading:false,
                currentGame: currentGame,
                language: null
              })
              swal({
                title: 'Emails Sent!',
                type: 'success',
                toast: true,
                position: 'top-end',
                showConfirmButton: true,
                timer: 10000
              })
            } else {
              swal({
                title: 'Something went wrong!  Check your internet connection and try again!',
                type: 'error',
                toast: true,
                position: 'top-end',
                showConfirmButton: true,
                timer: 10000
              })
              vm.setState({
                loading: false
              })
            }
          })
        } else {
          swal({
            title: 'Something went wrong!  Check your internet connection and try again!',
            type: 'error',
            toast: true,
            position: 'top-end',
            showConfirmButton: true,
            timer: 10000
          })
          this.setState({
            loading: false
          })
        }
      }).catch(err => {
        swal({
          title: 'Something went wrong!  Check your internet connection and try again!',
          type: 'error',
          toast: true,
          position: 'top-end',
          showConfirmButton: true,
          timer: 10000
        })
        this.setState({
          loading: false
        })
      })
    }

    toggleWizardLocation(tabClicked, otherTab1, otherTab2){
      this.setState({
        wizardLocation: tabClicked
      })
      document.getElementById(tabClicked).classList.add('active');
      document.getElementById(tabClicked).classList.add('show');
      document.getElementById(otherTab1).classList.remove('active');
      document.getElementById(otherTab1).classList.remove('show');
      const thirdTab = document.getElementById(otherTab2);
      const thirdTabOne = document.getElementById(otherTab2+'1');
      if(thirdTab && thirdTabOne){
        document.getElementById(otherTab2).classList.remove('active');
        document.getElementById(otherTab2).classList.remove('show');
        document.getElementById(otherTab2+'1').classList.remove('active');
        document.getElementById(otherTab2+'1').classList.remove('show');
      }
      document.getElementById(tabClicked+'1').classList.add('active');
      document.getElementById(tabClicked+'1').classList.add('show');
      document.getElementById(otherTab1+'1').classList.remove('active');
      document.getElementById(otherTab1+'1').classList.remove('show');
    }

    async switchStatsPrizes(buttonClicked){
      if(buttonClicked === "showStats"){
        document.getElementById('showPrizes').classList.remove('active');
        document.getElementById('showGames').classList.remove('active');
        document.getElementById('showStats').classList.add('active');
        this.setState({
          showStats:true,
          showPrizes:false,
          showGames:false
        })
      }else if(buttonClicked === "showPrizes"){
        document.getElementById('showPrizes').classList.add('active');
        document.getElementById('showStats').classList.remove('active');
        document.getElementById('showGames').classList.remove('active');
        this.setState({
          loading: true
        })
        const organizeUserAnswers = await this.sortPlayersForAdmin();
        this.setState({
          showStats:false,
          showPrizes:true,
          showGames:false,
          loading: false,
          currentUserAnswersState: organizeUserAnswers[0],
          showLinkColumn: organizeUserAnswers[1]
        })
      } else if(buttonClicked === "showGames"){
        document.getElementById('showPrizes').classList.remove('active');
        document.getElementById('showGames').classList.add('active');
        document.getElementById('showStats').classList.remove('active');
        const futureGameList = await this.getFutureGames();
        this.setState({
          showStats:false,
          showPrizes:false,
          showGames:true,
          futureGamesList: futureGameList
        })
      }
    }

    removeFromToAddArray(index, name){
      let removeArray = this.state[name];
      removeArray.splice(index, 1)
      this.setState({
        [name]: removeArray
      })
    }

    async sortPlayersForAdmin(numberOfResults=0, searchData=null){
      let gameId = null;
      if(this.state.currentGame && this.state.currentGame.id){
        gameId = this.state.currentGame.id;
      }
      return await Promise.all([getSnapshotFromEndpoint('currentGame', appDatabasePrimaryFunctions), getSnapshotFromEndpoint('userGameHistory', appDatabasePrimaryFunctions, null, null, gameId, gameId), getSnapshotFromEndpoint('emailsSent', appDatabasePrimaryFunctions), getSnapshotFromEndpoint('users', appDatabasePrimaryFunctions), getSnapshotFromEndpoint('campaignRegistrants', appDatabasePrimaryFunctions, null, null, "timeStamp"), getSnapshotFromEndpoint('redeemedPrize', appDatabasePrimaryFunctions, null, null, 'gameId', gameId)]).then(async function(snapshots){
        let currentGame = {};
        if(snapshots[0].exists() && snapshots[0].val()){
          currentGame = snapshots[0].val();
        }
        let usersGameHistory = {};
        if(snapshots[1].exists() && snapshots[1].val()){
          usersGameHistory = snapshots[1];
        }
        let emailsSent = {};
        if(snapshots[2].exists() && snapshots[2].val()){
          emailsSent = snapshots[2].val();
        }
        let users = {};
        if(snapshots[3].exists() && snapshots[3].val()){
          users = snapshots[3].val();
        }
        let seasonUsers = {};
        if(snapshots[4].exists() && snapshots[4].val()){
          seasonUsers = snapshots[4];
        }
        let prizeRedemptions = {};
        if(snapshots[5].exists()){
          snapshots[5].forEach(function(data) {
            const redemptionObject = data.val();
            prizeRedemptions[redemptionObject.uid] = redemptionObject;
          })
        }
        const usersAnswersAttempted = [];
        let showLinkColumn = false;
        let listToIterate = usersGameHistory;
        if(currentGame.participants === "allTime"){
          listToIterate = seasonUsers;
        }
        //necessary incase there is no data to run through
        if(isObjectEmpty(listToIterate)){
          return [[], false]
        }
        listToIterate.forEach(function (userDataIndex) {
          const user = {};
          const userEmail = users[userDataIndex.key].email;
          let encodedRealEmail = btoa(userEmail);
          let rewardSent = emailsSent[encodedRealEmail] || emailsSent[userDataIndex.key] || false;
          let code = "";
          let link = "";
          if(rewardSent){
            code = rewardSent.code || "";
            link = rewardSent.link || "";
            if(link){
              showLinkColumn = true;
            }
            rewardSent = rewardSent.name;
          }
          user['email'] = userEmail;
          user['uid'] = userDataIndex.key;
          user['rewardSent'] = rewardSent;
          user['code'] = code;
          user['link'] = link;
          const redeemedPrize = prizeRedemptions[userDataIndex.key] || {};
          if(redeemedPrize.sendPrizeId){
            user['isRedeemed'] = redeemedPrize.redeemed;
            user['prizeWonId'] = redeemedPrize.sendPrizeId;
          }
          if(searchData){
            const cleanSearchData = searchData.toLowerCase();
            const lowerCaseEmail = userEmail.toLowerCase();
            let lowerCaseRewardName = "";
            if(rewardSent){
              lowerCaseRewardName = rewardSent.toLowerCase();
            }
            if(lowerCaseEmail.indexOf(cleanSearchData) !== -1 || lowerCaseRewardName.indexOf(cleanSearchData) !== -1){
              usersAnswersAttempted.push(user)
            }
          } else {
            usersAnswersAttempted.push(user)
          }
        })
        return [usersAnswersAttempted, showLinkColumn]
      })
    }

    async getFutureGames(numberOfResults = 10){
      return await base.fetch('futureGamesList', {
        context: this,
        asArray: true,
        queries: {
          orderByChild: 'activateGame/performAt'
        },
        then(data){
          return data
        }
      })
    }

    saveReward (evt) {
      this.setState({ rewardToAdd: evt.target.value });
    }

    addRewardToLocalArray(){
      const rewardAmount = this.state.rewardAmount;
      const rewardId = this.state.rewardToAdd;
      let allElsePrize = this.state.allElsePrize;
      const codes = this.state.codes;
      const links = this.state.links;
      const qrCodes = this.state.qrCodes;
      let codesArray = this.state.codesArray || "";
      let linksArray = this.state.linksArray || "";
      let pinsArray = this.state.pinsArray || "";
      codesArray = this.detectHowManyCodesEntered(codesArray);
      linksArray = this.detectHowManyCodesEntered(linksArray, true);
      pinsArray = this.detectHowManyCodesEntered(pinsArray);
      if(codesArray.length === 0 && codes && !allElsePrize){
        swal({
          title: 'Hold on!',
          text: 'Must enter codes or uncheck codes box!',
          type: 'warning',
          confirmButtonText: 'Ok'
        })
        return;
      } else if (codes && !allElsePrize){
        if(codesArray.length !== parseInt(rewardAmount,10)){
          swal({
            title: 'Codes not equal!',
            text: "# of codes must equal number of prizes created. In this case: " +  codesArray.length + " does not equal " + rewardAmount,
            type: 'warning',
            confirmButtonText: 'Ok'
          })
          return;
        } else if(pinsArray.length !== 0 && pinsArray.length !== parseInt(rewardAmount,10)){
          swal({
            title: 'Pins not equal!',
            text: "# of pins must equal number of prizes created. In this case: " +  pinsArray.length + " does not equal " + rewardAmount,
            type: 'warning',
            confirmButtonText: 'Ok'
          })
          return;
        }
      }

      if(linksArray.length === 0 && links && !allElsePrize){
        swal({
          title: 'Hold on!',
          text: 'Must enter links or uncheck links box!',
          type: 'warning',
          confirmButtonText: 'Ok'
        })
        return;
      } else if (links && !allElsePrize){
        if(linksArray.inValidUrl){
          swal({
            title: 'Invalid URL Detected!',
            text: "Links must be valid urls. In this case: " +  linksArray.inValidUrl + " is not valid",
            type: 'warning',
            confirmButtonText: 'Ok'
          })
          return;
        }
        if(linksArray.length !== parseInt(rewardAmount,10)){
          swal({
            title: 'Codes not equal!',
            text: "# of links must equal number of prizes created. In this case: " +  linksArray.length + " does not equal " + rewardAmount,
            type: 'warning',
            confirmButtonText: 'Ok'
          })
          return;
        }
      }

      if(!allElsePrize && (!rewardAmount || rewardAmount < 1 || rewardId.length < 1)){
        swal({
          title: 'Missing Amount',
          text: "Missing reward or amount of reward.  Can't add a reward to a game without an amount or selecting a reward",
          type: 'warning',
          confirmButtonText: 'Ok'
        })
        return;
      }

      const rewardToAddObject = {};
      for(const ticket in this.state.ticketList){
        if(this.state.ticketList[ticket].key === rewardId){
          rewardToAddObject.reward = this.state.ticketList[ticket];
          rewardToAddObject.allElsePrize = allElsePrize;
          rewardToAddObject.amount = allElsePrize ? null : rewardAmount;
          rewardToAddObject.codes = allElsePrize ? false : codes;
          rewardToAddObject.links = allElsePrize ? false : links;
          rewardToAddObject.codesArray = allElsePrize ? null : codesArray;
          rewardToAddObject.linksArray = allElsePrize ? null : linksArray;
          rewardToAddObject.qrCodes = allElsePrize ? false : qrCodes || false;
          rewardToAddObject.pinsArray = allElsePrize ? null : pinsArray;;
          rewardToAddObject.id = rewardId;
        }
      }
      let array = this.state.rewardsAdded;
      const rewardsAddedIds = []
      for(let prize in array){
        if(array[prize].allElsePrize && rewardToAddObject.allElsePrize){
          swal({
            title: 'Hold On!',
            text: 'Cannot add two "All Else" prizes',
            type: 'warning',
            confirmButtonText: 'Ok'
          })
          return
        }
        rewardsAddedIds.push(array[prize].reward.key)
      }
      if(rewardsAddedIds.indexOf(rewardId) === -1){
        array = array.concat(rewardToAddObject)
        this.setState({
          rewardsAdded: array,
          qrCodes: false,
          links: false,
          codesArray: "",
          pinsArray: "",
          linksArray: "",
          codes: false
        })
      } else {
        swal({
          title: 'Prize already added!',
          text: "You have already added this prize",
          type: 'warning',
          confirmButtonText: 'Ok'
        })
      }
  }

    async setRewardRedeemed(reward, index){
      const response = await swal({
        title: 'Hold on!',
        text: "This prizes redemption process is handled automatically by Sqwad, are you sure you want to toggle this?",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Toggle'
      })
      if(!response.value){
        return
      }
      let uid = reward.uid || "";
      const currentGame = this.state.currentGame || {};
      const gameId = currentGame.id || "";
      const prizeWonId = reward.prizeWonId || reward.sendPrizeId || "";
      if(!uid || !gameId || !prizeWonId){
        return
      }
      this.setState({
        loading: true
      })
      let valueToSet = false;
      const redeemedPrizeRef = await appDatabasePrimaryFunctions.ref('redeemedPrize').child(prizeWonId).once('value');
      const redeemedPrize = redeemedPrizeRef.val();
      if(redeemedPrize.redeemed){
        await appDatabasePrimaryFunctions.ref('redeemedPrize').child(prizeWonId).update({redeemed: false, timeRedeemed: null});
      } else {
        valueToSet = true;
        await appDatabasePrimaryFunctions.ref('redeemedPrize').child(prizeWonId).update({redeemed: true, timeRedeemed: new Date().getTime()});
      }
      const currentUserAnswersState = this.state.currentUserAnswersState;
      currentUserAnswersState[index].isRedeemed = valueToSet;
      this.setState({
        currentUserAnswersState: currentUserAnswersState,
        loading: false
      })
    }

    render() {
      let selectedGame = this.state.currentGame || {};
      const hasRewardWithAutoRedemptions = selectedGame.hasRewardWithAutoRedemptions || false;
      let selectedGameRewards = [];
      const futureGamesList = this.state.futureGamesList || [];
      let userAnswerCount = this.state.userGameHistory || 0;
      let answersCountText = "Current Game Players";
      if(selectedGame && selectedGame.participants === "allTime"){
        userAnswerCount = this.state.campaignRegistrantsCount || 0;
        answersCountText = "Season Game Players";
      }
      const tenantVariables = this.state.tenantVariables || {};
      const rewardsAdded = this.state.rewardsAdded || [];
      let gameActive;
      let startTimeFirstPart = null;
      let startTimeSecondPart = null;
      let endTimeSecondPart = null;
      let endTimeFirstPart = null;
      let showTime = false;
      let currentUserAnswersState = this.state.currentUserAnswersState || [];
      let buttonText = "Prizes Sent"
      if(selectedGame && selectedGame.gameName){
        gameActive = selectedGame.active;
        selectedGameRewards = selectedGame.rewards || [];
        if(selectedGameRewards.length === 0 || tenantVariables.doNotCollectEmail){
          buttonText = "Game Ended"
        }
        if(selectedGame.scheduleInfo || selectedGame.activateGame){
          const performAt = (selectedGame.scheduleInfo && selectedGame.scheduleInfo.performAt) ||  (selectedGame.activateGame && selectedGame.activateGame.performAt);
          startTimeFirstPart = new Date(performAt).toLocaleDateString();
          startTimeSecondPart = new Date(performAt).toLocaleTimeString();
          // endTimeFirstPart = new Date(selectedGame.scheduleInfo.endAt*1000).toLocaleDateString();
          // endTimeSecondPart = new Date(selectedGame.scheduleInfo.endAt*1000).toLocaleTimeString();
          if(performAt > Date.now()){
            showTime = true;
          }
        }
      } else {
        selectedGame = null;
        gameActive = false;
      }
      const vm = this;
      return (
        <div className="admin-wrapper">
          <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
          <SideMenu/>
          <TopMenu/>
          <div className="admin-main-panel">
            <div className="container" style={{padding:'20px', backgroundColor:'#e3eaef'}}>
              <div className="row">
                <div className="col-md-2">
                  <div className="card" style={{backgroundColor:'#00c78c', width:'100%', textAlign: 'center', height:'50px', display: gameActive ? '' : 'none', float:'left'}}>
                    <p style={{lineHeight:'50px'}}>Game Is Active</p>
                  </div>
                  <div className="card" style={{backgroundColor:'#fe3b4b', width:'100%', textAlign: 'center', height:'50px', display: gameActive ? 'none' : '', float:'left'}}>
                    <p style={{lineHeight:'50px'}}>Game is Inactive</p>
                  </div>
                </div>
                <div className="col-md-3">
                  {selectedGame && selectedGame.participants !== "immediate" &&
                      <button onClick={() =>  this.sendRewardsToPlayers()} className="btn btn-primary btn-lg start-game-button" style={{display: selectedGame && selectedGame.status !== 2 ? '' : 'none', float:'left', height:'52px'}}>
                        Send Prizes
                      </button>
                  }
                  <div className="card" style={{backgroundColor:'#fe3b4b', width:'100%', textAlign: 'center', height:'50px', display: selectedGame && selectedGame.status === 2 ? '' : 'none', float:'left'}}>
                    <p style={{lineHeight:'50px'}}>{buttonText}</p>
                  </div>
                </div>
                <center className="col-md-1" style={{display: gameActive ? '' : 'none', color:'black'}}>
                  Advanced<input id="advanceSettings" name="advanceSettings" type="checkbox" checked={this.state.advanceSettings} onChange={this.handleChange} />
                </center>
                <div className="col-md-2">
                  <button onClick={() => this.startGame()} className="btn btn-primary btn-lg start-game-button" style={{display: gameActive ? 'none' : '', float:'left', height:'52px'}}>Start Game</button>
                  <button onClick={() => this.stopGame()} className="btn btn-primary btn-lg end-game-button" style={{display: gameActive && this.state.advanceSettings ? '' : 'none', float:'left', height:'52px' }}>Stop Game</button>
                </div>
                <div className="col-md-4">
                  <a className="btn btn-outline-danger btn-sm" onClick={() => this.resetRace()} style={{display: selectedGame ? '' : 'none', marginTop:'7px', float:'right'}}>Reset Game</a>
                  <button onClick={() => this.toggle()} className="btn btn-primary btn-lg create-game-button" style={{float:'right', marginRight:'5px'}}>New Game</button>
                </div>
              </div>
            </div>
            <div style={{display: !selectedGame ? 'none': 'block'}}>
              <div className="container">
                <div className="row">
                  <div className="col-md-2" style={{color: "black", border: "solid 1px #d3d3d3", marginLeft:10, marginTop: 10, marginBottom:10, borderRadius: 5, display: !showTime && "none"}}>
                    <div className="row">
                      <div className="col-md-6">
                        <u style={{color:"#353839"}}>Starts</u>
                        <br/>
                        <span style={{fontSize:18}}>{startTimeFirstPart || "No Start Time"}<br/>{startTimeSecondPart}</span>
                      </div>
                      {/*<div className="col-md-6">*/}
                      {/*  <u style={{color:"#353839"}}>Ends</u>*/}
                      {/*  <br/>*/}
                      {/*  <span style={{fontSize:18}}>{endTimeFirstPart || "No End Time"}<br/>{endTimeSecondPart}</span>*/}
                      {/*</div>*/}
                    </div>
                  </div>
                  <div className="col-md-2" style={{color: "black", border: "solid 1px #d3d3d3", marginLeft: 10, marginTop:10, marginBottom:10, borderRadius: 5}}>
                    <div className="row">
                      <div className="col-md-12" style={{textAlign:'center'}}>
                        <span style={{fontSize:12}}>{answersCountText}</span>
                        <footer className="value-text">{userAnswerCount}</footer>
                      </div>
                    </div>
                  </div>
                  {hasRewardWithAutoRedemptions &&
                      <div className="col-md-2" style={{
                        color: "black",
                        border: "solid 1px #d3d3d3",
                        marginLeft: 10,
                        marginTop: 10,
                        marginBottom: 10,
                        borderRadius: 5
                      }}>
                        <div className="row">
                          <div className="col-md-12" style={{textAlign: 'center'}}>
                            <span style={{fontSize: 12}}>Redemptions</span>
                            <footer className="value-text">{this.state.redemptions || 0}</footer>
                          </div>
                        </div>
                      </div>
                  }
                </div>
              </div>
            </div>
            <div className="">
              <div className="card-body">
                <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                    <li className="nav-item" onClick={()=> this.switchStatsPrizes("showStats")}>
                        <a href="#" data-toggle="tab" aria-expanded="false" className="nav-link active" id="showStats" style={{backgroundColor:'#fafbfe'}}>
                            <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                            <span className="d-none d-lg-block">Game Details</span>
                        </a>
                    </li>
                    <li className="nav-item" onClick={()=> this.switchStatsPrizes("showPrizes")}>
                        <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showPrizes" style={{backgroundColor:'#fafbfe'}}>
                            <i className="mdi mdi-account-circle d-lg-none d-block mr-1"/>
                            <span className="d-none d-lg-block">Results</span>
                        </a>
                    </li>
                    <li className="nav-item" onClick={()=> this.switchStatsPrizes("showGames")}>
                        <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showGames" style={{backgroundColor:'#fafbfe'}}>
                            <i className="mdi mdi-account-circle d-lg-none d-block mr-1"/>
                            <span className="d-none d-lg-block">Future Games</span>
                        </a>
                    </li>
                </ul>
                <div style={{display: this.state.showPrizes ? 'block' : 'none'}}>
                  <div className="export-button-styles btn btn-primary btn-lg download-button" style={{marginBottom:10}} onClick={()=>this.downloadUsers()}>
                    <span className="fa fa-arrow-circle-down"/> Download Participants
                  </div>
                  <table className="table table-striped" style={{color:'black'}}>
                    <tbody>
                      <tr>
                        <th>{tenantVariables.doNotCollectEmail ? "UID" : "Email"}</th>
                        <th>Reward</th>
                        <th>Code</th>
                        {this.state.showLinkColumn && !hasRewardWithAutoRedemptions &&
                          <th>Link</th>
                        }
                        {hasRewardWithAutoRedemptions &&
                            <th>Redeemed</th>
                        }
                      </tr>
                      {
                        currentUserAnswersState.map(function(item,i){
                            return <tr key={i}>
                              <td style={{fontFamily:'Open Sans'}}>{tenantVariables.doNotCollectEmail ? item.uid : item.email}</td>
                              <td style={{fontFamily:'Open Sans' }}>{item.rewardSent || "NONE"}</td>
                              <td style={{fontFamily:'Open Sans' }}>{item.code || "NONE"}</td>
                              {this.state.showLinkColumn && !hasRewardWithAutoRedemptions &&
                                <td style={{fontFamily:'Open Sans' }}>{item.link || "NONE"}</td>
                              }
                              {hasRewardWithAutoRedemptions &&
                                  <td style={{fontFamily: 'Open Sans'}}>
                                    <input type="checkbox" id={i} data-switch="success" checked={item.isRedeemed} onClick={() => this.setRewardRedeemed(item, i)} readOnly/>
                                    <label htmlFor={i} data-on-label="Yes" data-off-label="No"/>
                                  </td>
                              }
                            </tr>
                        }, this)
                      }
                    </tbody>
                  </table>
                </div>
                <div style={{display: this.state.showStats ? 'block' : 'none'}}>
                  <div className="container">
                    <div className="row">
                      <div className="col-md-6" style={{textAlign:"center"}}>
                        <h4 style={{color:'black'}}>Prizes</h4>
                        {selectedGameRewards && Object.keys(selectedGameRewards).length > 0 ?
                            <>
                              {
                                Object.keys(selectedGameRewards).filter(function(possibleReward) {
                                  return typeof selectedGameRewards[possibleReward] === "object";
                                }).sort((a, b) => a.allElsePrize ? 1 : -1)
                                  .map(function(key, index){
                                  let reward = selectedGameRewards[key];
                                  return <div key={index} style={{color:'black'}}>
                                    {!reward.allElsePrize ?
                                        <div style={{color: "black"}}>
                                          <span>{reward.amount}, {reward.rewardName}</span>
                                        </div>
                                    :
                                      <div key={index} style={{color:"black"}}>
                                        <span>
                                          <span style={{color:"grey", fontWeight: 100, fontFamily: "sans-serif"}}>
                                            Everyone Else Wins
                                          </span> {reward.rewardName}
                                        </span>
                                      </div>
                                    }
                                  </div>
                                })
                              }
                            </>
                            :
                            <span style={{color:'black'}}>
                              No Prizes Added
                            </span>
                        }
                      </div>
                      <div className="col-md-6 justify-content-center" style={{textAlign:"center"}}>
                        <h4 style={{color:'black'}}>Details</h4>
                        {selectedGame && selectedGame.gameName &&
                          <div style={{textAlign:'left', color:'black'}}>
                            Game Name: {selectedGame.gameName}
                            <br/>
                            Prizes Sent To: {selectedGame.participants === "game" && "This games participants"}
                            {selectedGame.participants === "allTime" && "Season Long Users" }
                            {selectedGame.participants === "immediate" && "Immediate" }
                            {selectedGame.activateGame &&
                              <p>Scheduled for: {convertTimeStampToHumanReadable(selectedGame.activateGame.performAt)}</p>
                            }
                            {selectedGame.participants === "immediate" &&
                              <p>Odds: 1/{selectedGame.chanceToWin.toString()}</p>
                            }
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{display: this.state.showGames ? 'block' : 'none'}}>
                  <p style={{color:'black', marginLeft:20, fontSize:20, marginTop:20}}>Future Games</p>
                  <table className="table table-striped" style={{color:'black'}}>
                    <tbody>
                      <tr>
                        <th>Name</th>
                        <th>Date</th>
                        <th>Rewards</th>
                        <th>Make Current Game</th>
                        <th>Delete Game</th>
                      </tr>
                      {
                        futureGamesList.map(function(item,i){
                            const dateTime = convertTimeStampToHumanReadable(item.activateGame.performAt)
                            return <tr key={i}>
                              <td style={{fontFamily:'Open Sans'}}>{item.gameName}</td>
                              <td style={{fontFamily:'Open Sans' }}>{dateTime}</td>
                              <td style={{fontFamily:'Open Sans' }}>{!item.rewards ? "None" : item.rewards.totalRewards ? item.rewards.totalRewards : "Unlimited"}</td>
                              <td style={{fontFamily:'Open Sans' }}><button className="btn btn-primary" onClick={()=>this.saveDeleteCurrentGameDataReplace(this.state.currentGame, item, true)}>MAKE CURRENT</button></td>
                              <td style={{fontFamily:'Open Sans' }}><button className="btn btn-danger" onClick={()=>this.removeGameFromFutureList(item.id)}>DELETE</button></td>
                            </tr>
                        }, this)
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="myModal">
              <div className="card">
                <div className="card-body">
                  <h4 className="header-title mb-3"> Create Game</h4>
                  <div id="rootwizard">
                    <ul className="nav nav-pills bg-dark-light nav-justified mb-3">
                        <li className="nav-item" onClick={() => this.toggleWizardLocation('first', 'second', 'third')}>
                          <a href="#" className="nav-link rounded-0 pt-2 pb-2 active show" id="first1">
                            <span className="fa fa-pencil-square-o"/>
                            <span className="d-none d-sm-inline"> Game Details</span>
                          </a>
                        </li>
                        <li className="nav-item" onClick={() => this.toggleWizardLocation('second', 'first', 'third')}>
                            <a href="#" className="nav-link rounded-0 pt-2 pb-2" id="second1">
                                <span className="fa fa-trophy"/>
                                <span className="d-none d-sm-inline"> Add Prizes</span>
                            </a>
                        </li>
                        <li className="nav-item" onClick={() => this.toggleWizardLocation('third', 'first', 'second')}>
                            <a href="#" className="nav-link rounded-0 pt-2 pb-2" id="third1">
                                <span className="fa fa-sign-out"/>
                                <span className="d-none d-sm-inline"> Finish</span>
                            </a>
                        </li>
                    </ul>

                    <div className="tab-content mb-0 b-0" style={{fontFamily:'Roboto'}}>
                         <div className="tab-pane active show" id="first">
                           <form id="accountForm" method="post" action="#" className="form-horizontal">
                             <div className="row">
                               <div className="col-12">
                                 <div className="form-group row mb-3">
                                   <label className="col-md-3 col-form-label" htmlFor="gameName"> Game Name</label>
                                   <div className="col-sm-9">
                                     <input id="gameName" name="gameName" className="form-control" type="text" value={this.state.gameName} onChange={this.handleChange} placeholder="Example: 10/13 Game Dallas" />
                                   </div>
                                 </div>
                               </div>
                             </div>
                             <div className="row">
                               <div className="col-12">
                                 <div className="form-group row mb-3">
                                   <label className="col-md-12 col-form-label" htmlFor="participants"> Participants</label>
                                   <div className="col-md-9">
                                     <input type="radio" value="game" name="participants" checked={this.state.participants === "game"} onChange={this.handleChange}/> <span style={{marginRight:10, fontSize:10}}>Game</span>
                                     <input type="radio" value="allTime" name="participants" checked={this.state.participants === "allTime"} onChange={this.handleChange}/> <span style={{marginRight:10, fontSize:10}}>Season Long</span>
                                     <input type="radio" value="immediate" name="participants" checked={this.state.participants === "immediate"} onChange={this.handleChange}/> <span style={{marginRight:10, fontSize:10}}>Immediate</span>
                                     <p className="text-muted2" style={{fontSize:'10px', display: this.state.participants === "game" ? 'block' : 'none'}}>Only distribute prizes to people who are participating in this contest</p>
                                     <p className="text-muted2" style={{fontSize:'10px', display: this.state.participants === "allTime" ? 'block' : 'none'}}>Distribute prizes to Season Registrants</p>
                                     <p className="text-muted2" style={{fontSize:'10px', display: this.state.participants === "immediate" ? 'block' : 'none'}}>Distribute prizes immediately</p>
                                   </div>
                                 </div>
                               </div>
                             </div>
                             {this.state.participants === "immediate" &&
                                 <div className="row">
                                   <div className="col-3">
                                     <div className="form-group row mb-3">
                                       <label className="form-label" htmlFor="chanceToWin">Chance to win: 1/</label>
                                       <input className="form-control" value={this.state.chanceToWin} name="chanceToWin" type="number" min="0" onChange={this.handleChange}/>
                                     </div>
                                   </div>
                                 </div>
                             }
                           </form>
                         </div>
                        <div className="tab-pane fade" id="second">
                           <div className="form-horizontal">
                             <div className="row">
                               <div className="col-12" style={{textAlign:'center'}}>
                                 <div className="form-check">
                                   <input style={{float: "none", marginRight:3}} value={this.state.noPrizes} className="form-check-input" id="noPrizes" name="noPrizes" type="checkbox" checked={this.state.noPrizes} onChange={this.handleChange} />
                                   <label className="form-check-label" htmlFor="codes">No Prizes</label>
                                 </div>
                               </div>
                             </div>
                             <div className="row">
                               <div className="col-12">
                                 <div className="form-group row mb-3">
                                   <label className="col-md-3 col-form-label" htmlFor="name3"> Select Prize</label>
                                   <div className="col-md-6">
                                     <select className="form-control" onChange={this.saveReward}>
                                       <option/>
                                       {
                                         this.state.ticketList.map(function(item,index){
                                           return(
                                               <option value={item.key} key={index}>{item.rewardName}</option>
                                           )
                                         })
                                       }
                                     </select>
                                   </div>
                                   <div className="col-md-3" style={{alignSelf:"center", textAlign: "right"}}>
                                     <div className="form-check">
                                       <input id={`allElsePrize`} className="form-check-input" name={`allElsePrize`} type="checkbox" checked={this.state.allElsePrize} onChange={this.handleChange} />
                                       <label className="form-check-label" htmlFor={`allElsePrize`}>All Else Prize</label>
                                     </div>
                                   </div>
                                 </div>
                                 <div className="form-group row mb-3" style={{display: !this.state.allElsePrize ? 'flex' : 'none', alignItems:'center'}}>
                                   <label className="col-md-3 col-form-label" htmlFor="rewardAmount"> How Many Of This Prize To Give Out?</label>
                                   <div className="col-md-4">
                                     <input id="rewardAmount" name="rewardAmount" type="number" className="form-control" value={this.state.rewardAmount} onChange={this.handleChange} placeholder="5" />
                                   </div>
                                   <div className="form-check" style={{marginLeft: 5}}>
                                     <input value={this.state.codes} className="form-check-input" id="codes" name="codes" type="checkbox" checked={this.state.codes} onChange={this.handleChange} />
                                     <label className="form-check-label" htmlFor="codes">Add Codes</label>
                                   </div>
                                   <div className="form-check" style={{display: this.state.codes ? '' : 'none', marginLeft: 5}}>
                                     <input value={this.state.qrCodes} className="form-check-input" id="qrCodes" name="qrCodes" type="checkbox" checked={this.state.qrCodes} onChange={this.handleChange} />
                                     <label className="form-check-label" htmlFor="qrCodes">Convert Codes To QRCode?</label>
                                   </div>
                                   <div className="form-check" style={{marginLeft: 5}}>
                                     <input value={this.state.links} id={`links`} className="form-check-input" name={`links`} type="checkbox" checked={this.state.links} onChange={this.handleChange} />
                                     <label className="form-check-label" htmlFor={`links`}>Add Links</label>
                                   </div>
                                 </div>

                                 <div className="form-group row mb-3" style={{display: this.state.codes && !this.state.allElsePrize ? 'flex' : 'none' }}>
                                   <label htmlFor="codesArray" className="col-md-3 col-form-label">Enter Codes (1234,1238,1230)</label>
                                   <div className="col-md-9">
                                     <textarea value={this.state.codesArray} className="form-control" name="codesArray" onChange={this.handleChange} placeholder="1235,1238,1230"/>
                                   </div>
                                 </div>

                                 <div className="form-group row mb-3" style={{display: this.state.codes && !this.state.allElsePrize ? 'flex' : 'none' }}>
                                   <label htmlFor="pinsArray" className="col-md-3 col-form-label">Enter Pins</label>
                                   <div className="col-md-9">
                                     <textarea value={this.state.pinsArray} className="form-control" name="pinsArray" onChange={this.handleChange} placeholder="pin1,pin2,pin3"/>
                                   </div>
                                 </div>

                                 <div className="form-group row mb-3" style={{display: this.state.links && !this.state.allElsePrize ? 'flex' : 'none'}}>
                                   <label htmlFor="linksArray" className="col-md-3 col-form-label">Enter links</label>
                                   <div className="col-md-9">
                                     <textarea value={this.state.linksArray} className="form-control" name="linksArray" onChange={this.handleChange} placeholder="https://myfirstlink.com, https://mysecondlink.com, https://mythirdlink.com"/>
                                   </div>
                                 </div>

                                 <div className="form-group row mb-3" align="center">
                                   <div className="col-md-12">
                                     <button className="btn btn-primary btn-admin" onClick={()=>this.addRewardToLocalArray()}>Add Reward</button>
                                   </div>
                                 </div>

                                 {rewardsAdded && rewardsAdded.length > 1 &&
                                   <div className="form-check" >
                                     <input value={this.state.randomOrder} className="form-check-input" id="randomOrder" name="randomOrder" type="checkbox" checked={this.state.randomOrder} onChange={this.handleChange} />
                                     <label className="form-check-label" htmlFor="randomOrder">Give Out In Random Order</label>
                                   </div>
                                 }

                                 <div className="form-group mb-3">
                                   <ol style={{listStylePosition: 'inside', textAlign:'left'}} className="offset-md-0 col-md-10">
                                     {
                                       rewardsAdded.map(function(item,index){
                                         let codesText = "No"
                                         if(item.codes){
                                           codesText = "Yes"
                                         }
                                         return(
                                             <div key={index} className="form-group">
                                               <li>) {item.amount || "All Else Prize"} - {item.reward.rewardName}; Codes: {codesText} <span style={{float:'right'}} className="fa fa-trash-o" onClick={()=>vm.removeFromToAddArray(index, "rewardsAdded")}/></li>
                                             </div>
                                         )
                                       })
                                     }
                                   </ol>
                                 </div>
                               </div>
                             </div>
                           </div>
                        </div>

                        <div className="tab-pane fade" id="third">
                            <form id="otherForm" method="post" action="#" className="form-horizontal"/>
                                <div className="form-check">
                                  <input value={this.state.schedule_game} className="form-check-input" id="schedule_game" name="schedule_game" type="checkbox" checked={this.state.schedule_game} onChange={this.handleChange} />
                                  <label className="form-check-label" htmlFor="schedule_game">Schedule Game</label>
                                </div>
                                <div className="form-group row mb-3 form_times" style={{display: this.state.schedule_game ? 'flex' : 'none' }}>
                                    <label className="col-md-3 col-form-label" htmlFor="schedule_game_time"> Game Start Time</label>
                                    <div className="col-md-9">
                                      <DatePicker showTimeInput dateFormat="Pp" selected={this.state.schedule_game_time} onChange={date => this.setState({schedule_game_time: date})}/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="text-center">
                                            <h2 className="mt-0">
                                                <i className="mdi mdi-check-all"/>
                                            </h2>
                                            <h3 className="mt-0">Finish</h3>
                                            <div className="row form-group">
                                              <div className="col-md-12">
                                                <p>You are all set to create a game!  Before clicking "Create Game" make sure all the settings are the way you want. Feel free to navigate back to edit anything you like!</p>
                                              </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                        </div>

                        <ul className="list-inline wizard mb-0">
                            <li className="previous list-inline-item" style={{display: this.state.wizardLocation !== 'first' ? '' : 'none' }}><a href="#" className="btn btn-primary btn-lg previous-wizard-button" onClick={() => this.navButtonClicked('prev')}>Previous</a></li>
                            <li className="next list-inline-item float-end"><a href="#" className="btn btn-primary btn-lg next-wizard-button" onClick={() => this.navButtonClicked('next')} style={{display: this.state.wizardLocation !== 'third' ? '' : 'none' }}>Next</a></li>
                            <li className="next list-inline-item float-end"><a href="#" className="btn btn-primary btn-lg creategame-wizard-button" onClick={() => this.createGame()} style={{display: this.state.wizardLocation === 'third' ? '' : 'none' }}>Create Game</a></li>
                        </ul>

                      </div>
                    </div>
                </div>
              </div>
          </Modal>
       </div>
      );
    }
}

export default SetUpGame;
